import styled from 'styled-components'

export const Content = styled.div`
  position: relative;
  min-height: 20px;
`;

export const Valor = styled.p`
`;

export const Imagem = styled.img`
  position: absolute;
  top: 0;
  right: 0;
`;
