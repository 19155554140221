import styled from 'styled-components'

export const Container = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

export const Botoes = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Lista = styled.div`
  border: 2px solid #BFB3AF;
  padding: 11px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const InputCid = styled.input`
  width: 100px;
  border-radius: 4px;
  border: 1px solid #BFB3AF;
  transition: all 100ms;
  height: 32px;
  padding: 2px 8px;
  color: #000;  
`;

export const Input = styled.input`
  border-radius: 4px;
  border: 1px solid #BFB3AF;
  transition: all 100ms;
  height: 32px;
  padding: 2px 8px;
  color: #000;  
`;

export const Linha = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
`;

export const ListaItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ItemTexto = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Botao = styled.button`
  background: transparent;
  border: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #811EF9;
  & > img {
    margin-right: 8px;
  }
`;
