import { useState, useEffect } from "react";
import { Select as NewSelect} from "@components/Select";
import { useConsulta } from '@hooks/useConsulta';
import { getTecnologiasDisponiveis } from '@utils/conversaoV2';

import BtnRemoverUm from '../../assets/btn_remover_um.svg';
import IconRemover from '../../assets/icon_remover.svg';
import IconAdd from '../../assets/icon_add.svg';

import { 
  BotoesLinha,
  BotaoRecomendacoes,
  Recomendacoes as Container,
  RecomendacoesLista,
  RecomendacaoLinha,
  Marca,
  MiniLabel
} from './styles';
import { TecnologiaDTO } from "@dtos/ConsultaDTO";

interface Props {
  recomendacoes: TecnologiaDTO[];
}

export function Recomendacoes({ recomendacoes }: Props) {
  const [isAdding, setIsAdding] = useState(false);
  const [recomendacoesOptions, setRecomendacoesOptions] = useState([]);

  const { 
    consultaData,
    receita,
    alteraReceita
  } = useConsulta();  

  useEffect(() => {
    // carrega opcoes
    var tecnologiasDisponiveis = consultaData ? getTecnologiasDisponiveis(consultaData) : [];
    if(tecnologiasDisponiveis.length > 0) {
      var optionRecomendacoes = tecnologiasDisponiveis.map(tecnologia => ({
        value: tecnologia.id,
        label: tecnologia.nome,
        sublabel: tecnologia.fabricante,
        descricao: tecnologia.descricao
      }));
      setRecomendacoesOptions(optionRecomendacoes);
    }
  }, []);

  function handleIncluir(e) {
    e.preventDefault();
    setIsAdding(!isAdding);
  }

  function handleAdiciona(option) {
    // verifica se ja existe
    if(recomendacoes) {
      const exist = recomendacoes.find((element) => {
        return element.id === option.value;
      });
      if(exist) {
        setIsAdding(false);
        return;
      }
    }
    // adiciona
    var new_recomendacoes = recomendacoes ? recomendacoes : [];
    new_recomendacoes.push({
      id: option.value,
      nome: option.label,
      marca: option.sublabel,
      descricao: option.descricao
    });
    alteraReceita({...receita, tecnologias: new_recomendacoes});
    setIsAdding(false);
  }

  function handleRemoverTodas() {
    alteraReceita({...receita, tecnologias: []});
  }

  function handleRemover(value) {
    let filteredArray = recomendacoes.filter(item => item.id !== value);
    alteraReceita({...receita, tecnologias: filteredArray});
  }
  
  return (
    <Container>
      <MiniLabel className="ui_mini_labels">Tecnologias indicadas</MiniLabel>
      <BotoesLinha>
        <BotaoRecomendacoes onClick={handleIncluir} className="ui_labels">
          <img src={IconAdd} alt="incluir"/>Adicionar tecnologia
        </BotaoRecomendacoes>
        {recomendacoes && recomendacoes.length > 0 &&
        <BotaoRecomendacoes onClick={handleRemoverTodas} className="ui_labels" style={{ display: "flex", justifyContent: "center" }}>
          <img src={IconRemover} alt="remover"/>Remover todas
        </BotaoRecomendacoes>}
      </BotoesLinha>
      {isAdding &&
        <div style={{marginBottom: '10px'}}>
          <NewSelect 
            options={recomendacoesOptions} 
            onChange={handleAdiciona} 
            autoFocus={true}
            placeholder="Escolha…"
          />
        </div>
      }
      
      { recomendacoes && recomendacoes.length > 0 &&
      <RecomendacoesLista>
        {recomendacoes.map((recomendacao) => (
            <RecomendacaoLinha key={recomendacao.id}>
              <p >{recomendacao.nome} <Marca className="ui_mini_labels">{recomendacao.marca}</Marca></p>
              <img src={BtnRemoverUm} onClick={() => handleRemover(recomendacao.id)} alt="remover"/>
            </RecomendacaoLinha>
          ))
        }
      </RecomendacoesLista>
      }
    </Container>
  );
}
