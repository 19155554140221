import Routes from "./routes";
import GlobalStyle from "./styles/global";
import { AuthContextProvider } from './contexts/AuthContext';
import { ToastContainer, toast } from 'react-toastify';

const App = () =>
<AuthContextProvider>
  <GlobalStyle />
  <Routes />
  <ToastContainer />
</AuthContextProvider>
;
export default App;