import styled from 'styled-components'

export const Content = styled.div`
`;

export const Label = styled.div` 
  color: #BFB3AF;
  margin-bottom: 6px;
  white-space: nowrap;
`;

export const Nome = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #000;
`;

export const Linha = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  justify-content: space-between;
`;

export const Campo = styled.div.attrs(({ error }): any => ({
  error: error || "false",
}))`
  width: 100%;
  //display: flex;
  //flex-direction: column;
  //width: ${props => props.error === "true" ? "100%" : "auto"};
  //width: 100%;
  //flex: ${props => props.error === "true" ? 1 : 0};
`;

export const LabelDadosClinicos = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.02em;
  text-align: left;

  color: #811EF9;

  margin-top: 10px;
`;

export const PreAnamnese = styled.div`
  padding-left: 10px;
`;

export const Diagnosticos = styled.div`
  padding-left: 10px;
`;

export const CodigoCid = styled.span`
  font-size: 10px;
`;