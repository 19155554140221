import axios, { AxiosError, AxiosInstance }  from "axios";
import { AppError } from "../utils/AppError";
import { storageAuthTokenGet, storageAuthTokenSave } from "../storage/storageAuthToken";

type SignOut = () => void;

type PromiseType = {
  onSuccess: (token: string) => void;
  onFailure: (error: AxiosError) => void;
}

type APIInstanceProps = AxiosInstance & {
  registerInterceptTokenManager: (signOut: SignOut) => () => void;
}

const baseURL = "https://equipe.ver.ai/api/extension/";

const api = axios.create({
  baseURL: baseURL
}) as APIInstanceProps;

let failedQueued: Array<PromiseType> = [];
let isRefreshing = false;

api.registerInterceptTokenManager = singOut => {
  const interceptTokenManager = api.interceptors.response.use((response) => response, async (requestError) => {
    if(requestError.response?.status === 401 || requestError.response?.status === 403) {
      if(requestError.response.data?.message === 'Token inválido ou expirado.' || requestError.response.data?.message === 'token.invalid') {
        const { refresh_token } = await storageAuthTokenGet();

        if(!refresh_token) {
          singOut();
          return Promise.reject(requestError)
        }

        const originalRequestConfig = requestError.config;

        if(isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueued.push({
              onSuccess: (token: string) => {
                originalRequestConfig.headers = { 'Authorization': `Bearer ${token}` };
                resolve(api(originalRequestConfig));
              },
              onFailure: (error: AxiosError) => {
                reject(error)
              },
            })
          })
        }

        isRefreshing = true

        return new Promise(async (resolve, reject) => {
          try {
            const { data } = await api.post('/sessions/refresh-token', { refresh_token });
            await storageAuthTokenSave({ token: data.token, refresh_token: data.refresh_token });

            if(originalRequestConfig.data) {
              originalRequestConfig.data = JSON.parse(originalRequestConfig.data);
            }

            originalRequestConfig.headers = { 'Authorization': `Bearer ${data.token}` };
            api.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;

            failedQueued.forEach(request => {
              request.onSuccess(data.token);
            });

            console.log("TOKEN ATUALIZADO");

            resolve(api(originalRequestConfig));
          } catch (error: any) {
            console.log(error)
            failedQueued.forEach(request => {
              request.onFailure(error);
            })

            singOut();
            reject(error);
          } finally {
            isRefreshing = false;
            failedQueued = []
          }
        })

      }

      singOut();

    }

    if(requestError.response && requestError.response.data) {
      if(requestError.response.data.hasOwnProperty("message")) {
        return Promise.reject(new AppError(requestError.response.data.message))
      } else if(requestError.response.data.hasOwnProperty("Status")) {
        return Promise.reject(new AppError(requestError.response.data.Status))
      } else {
        return Promise.reject(new AppError(requestError.response.data))
      }
    } else {
      return Promise.reject(requestError)
    }

  });

  return () => {
    api.interceptors.response.eject(interceptTokenManager);
  }
}

export { api };